body{
    padding: 0;
    margin: 0 auto;
    background-color: #EAE7DC ;
    
}
.HomePage{
    
    width: 170%;
    display:flex;
    flex-direction: row;
    padding-left: 14px;
    background-color: #C6D8FF;
}
.Filter__Column{
    display: flex;
    flex-direction: column;
    text-align: center;
    width:fit-content;
    height: 100%;
    
    
}
.Filter__Row{
    display: flex;
    flex-direction: row;
    
    
}
.Filter__Page{
    
    height: 40vh;
    justify-content:flex-start;
    background-color: #C6D8FF;
}

.Filter__Title{
    width: 100%;

}

.FilterSearch__Page{
    display:flex;
    flex-direction: row;
}

.Filter__Result{
    overflow-y: scroll;
    width: 100%;
    height: 90vh;
    background-color:#FFFADD
}
.Result__Row{
display: flex;
flex-direction: row;
width: 100%;
border-radius: 3px;
justify-content: center;
align-items: center;

}
.Result__Column{
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;
width: 100%;


}

.Result__Staff_Photo{

    width: 60%;
}
.Filter__Result::-webkit-scrollbar {
    width: 10px;
    background-color: #f9f9fd;
    
  }
  
  .Filter__Result::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #18aaaa;
  }
  
  .Filter__Result::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: #f9f9fd;
  }