.Expirience__Page__Full{
    display:inline;
    flex-direction: row;

    text-align: center;
    justify-content:center;
    width: 100vw;
}
textarea{
    width: 30vw!important;
}
.Expirience__Page__Full select{
    width: 30vw;
    margin-top: 1vh;
}
.Expirience__Page__Full input{
    margin-top: 1vh;
    width:30vw;
}
.ExpiriencePage__Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
}
.ExpiriencePage{
    display: flex;
    width: max-content;
    flex-direction: row;
    justify-content: center;
}
.ExpiriencePage__Form{
    display: flex;
    flex-direction: column;
    
}
.ExpiriencePage__Result{
    display: flex;
    flex-direction: column;
    width: max-content;
    justify-content: center;
    margin-top: 1vh;
    
}
.ExpiriencePage__Result__Row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 30vw;
}
.ExpiriencePage__Result__Row__Remove{
    
    font-weight: bolder;
    color: #ff1908;
    cursor: pointer;
    font-size: 30px;
}
.ExpiriencePage__Result__Column{
    display: flex;
    flex-direction: column;
    border:1px dashed #E85A4F ;
    border-radius: 5px;
    background-color: #D8C3A5 ;
    width: 30vw;
}
.Error{
    color:#E85A4F;
    border: #E85A4F solid 1px;
}
@supports (display: flex) {
    @media screen and (max-width: 600px){
.ExpiriencePage__Container{
    flex-direction: column;
}
.ExpiriencePage__Form{
    width: 100vw;
    justify-content: center;
    align-items: center;
}

.ExpiriencePage__Result{
    width: 100vw;
    justify-content: center;
    align-items: center;
}
.ExpiriencePage__Result__Row{
    width: 50vw;
}
.ExpiriencePage__Result__Column{
    width: 50vw;
}
}
}