
.RegisterPage{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 85vh;
    max-height: 130vh;
    
}
.RegisterPage input{
    width:15vw;
    text-align: center;
}
.RegisterPage select{
    width: 10vw;
    text-align: center;
}
.RegisterPage__Row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 15vw;
    word-wrap:normal;
    max-width: 40vw;
    width:26vw;
    font-size:10pt;
}

.RegisterPage__Container{
    width: 50vw;
    font-family:'Courier New', Courier, monospace;
    color:#2E343B;
}
.RegisterPage textarea{
    width: 180%;
    height: 20vh;
}
.RegisterPage  .Labeled_CheckBox{
    display: flex;
    flex-direction: row;
}
.RegisterPage_Duties{
    display: flex;
    flex-direction: column;
    height: 65vh;
    flex-wrap: wrap;
    margin: 0 auto;
    align-self: start;
    margin-left: -2vw;
}
.RegisterPage_Duties .RegisterPage__Row {
    width:fit-content;
    margin-left: 0;
    
}
.Error{
    color:#E85A4F;
    border:solid #E85A4F  3px;
    background-color: #e8594f13;
    max-width: 40vw;
    word-wrap:normal;
}
.Registration__Page_{
    display: flex;
    height: 92vh;
    width: 98.5vw;
    margin: 0 auto;
    align-items: center;
}
.Registration__Page__BackArrow{
    font-size: 24pt;
    font-weight: bolder;
    color:#3F704D;
    border:#3F704D solid 2px;
    
}
.Registration__Page_ button{
    font-size: 18pt;
    font-weight: bolder;
    color:#3F704D;
    border:#3F704D solid 2px;
    background-color: #3f704d8e;
    background: none;
    border-radius: 4px;
}
.Registration__Page_ button:disabled{
    color:#28382d;
    border-color: #28382d;
    opacity: 30%;
}
.Registration__Page__BackArrow:hover{
    cursor: pointer;
}
.Register__Page__Catrgory{
    display: flex;
    width: 40vw;
    font-size: 20pt;
    height: 10vh;
    background-color: #E98074;
    border-radius: 20px;
    text-align: center;
    color: white;
    margin-top: 0.7vh;
    align-items: center;
    justify-content: center;

    
    
}
.Register__Page__Catrgory:hover{
    cursor: pointer;
    background-color: #bcd2d1;
}
.Register__Page__Category__Disabled{
    pointer-events: none;
}
.RegisterPage__AnketaResult{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.RegisterPage__AnketaResult img{
    width: 15vw;
}
.Registration__Page__Categories{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.RegisterPage__Row__Phone{
    display: flex;
    flex-direction: row;
    justify-content: start;
    word-wrap:normal;

    font-size:10pt;
}
.RegisterPage__Row__Phone input{
    width: 2vw;
}
.Register__Required::after{
    content: "*";
    color:#E98074;
    font-size: 20pt;
}


@supports (display: flex) {
@media screen and (max-width: 600px){
    .Registration__Page_ h2{
        font-size: 18px!important;
    }
    .Registration__Page_{
        font-size: 20px!important;
    }
    .RegisterPage__Container{
        width: 100vw;
        
    }
    .container{
        width: 100vw;
    }
    .RegisterPage{
        width: 100vw;
    }

    input[type=text],input[type=number], input[type=email],[type=date],select{
        width: 50vw!important;
    }
    input[type=checkbox]{
        max-width: 10vw!important;
        min-width: 10vw!important;
        width: 10vw!important;
        height: 10vw!important;
        max-height: 10vw!important;
        min-height: 10vw!important;
    }
    textarea{
        width: 100vw!important;
    }
    .Register__Page__Catrgory{
        width: 85vw;
    }
    .RegisterPage__Row__Phone{
        width: 70vw!important;
        margin-left: 12vw;
    }
    .RegisterPage__Row__Phone input{
        width: 10vw!important;
    }
    .TermsOfUse__Input{
        font-size: 8pt;
    }
    .TermsOfUse__Input input{
        width: 20vw!important;
    }
    .Error{
        font-size: 18pt;
        max-width: 85vw;
        
    }
}
}