.Anketa__Page{
height: 95vh;
width: 100vw;
display: flex;
flex-direction: column;
flex-wrap: wrap;
overflow-x:hidden; 
}
.Text_Input{
    text-align: center;
    width: 12vw;
}
.Labeled_Input{
    display: flex;
    flex-direction: column;
    border: solid black 1px;
    width: 12vw;

}
.Labeled_CheckBox{
    display: flex;
    flex-direction: row;
    width: 12vw;
}
.Anketa__Filter__Result{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
}
.Anketa__Page select {
    width: 13vw;
}
.Anketa__Page input[type=text]{
    width: 13vw;
}